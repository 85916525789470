export const isAddressNeeded = (method, channel, region) => {
  const config = [
    "CreditCard-CardPay",
    "CreditCard-Paypal",
    "CreditCard-OpenPay-MEX",
    "CreditCard-OpenPay-EUP",
    "CreditCard-E-Comprocessing",
    "RecurringCreditCard-Paypal",
    "PlayersBankCard-Paypal",
    "CreditCard-CrediMax",
    "CreditCard-Xendit",
    "CreditCard-Localpayment",
    "DebitCard-Localpayment",
    "CreditCard-Movii",
    "Sofort-Emerchantpay",
    "Trustly-Emerchantpay",
    "Neosurf-Emerchantpay",
    "Bancontact-Emerchantpay",
    "EPS-Emerchantpay",
    "Giropay-Emerchantpay",
    "iDeal-Emerchantpay",
    "MyBank-Emerchantpay",
    "P24-Emerchantpay",
    "CreditCard-Izipay",
    "CreditCard-IzipaySmileone",
    "CreditCard-Adiq",
    "CreditCard-Multicaja",
    "Wallet-Fpay",
    "CreditCard-Mercadopago",
    "CreditCard-Bamboo",
    "CreditCard-Kushki",
  ];

  return config.includes(method + "-" + channel + "-" + region) || config.includes(method + "-" + channel);
};

export default function getFromConfig({ region, method, channel, isAddressSame, providerId }) {
  const form2 = ["name", "taxId", "email", "phone"];
  const form3 = ["name", "state", "city", "postalCode", "street", "no", "email", "phone"];

  // common for all countries
  const formConfig = {
    "CreditCard-OpenPay": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-E-Comprocessing": [
      "firstName",
      "lastName",
      "cardNumber",
      "expYear",
      "expMonth",
      "cvc",
      "email",
      "phone",
    ],
    "CreditCard-CrediMax": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-CardPay": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-Monnet": ["firstName", "lastName", "email", "phone", "taxId"],
    "CreditCard-Paypal": ["email", "phone"],
    "RecurringCreditCard-Paypal": ["email", "phone"],
    "CreditCard-Xendit": ["firstName", "lastName", "email", "phone", "cardNumber", "expYear", "expMonth", "cvc"],
    "CreditCard-QIWI": ["name", "email"],
    "Wallet-Todito": [...form2, "todito_cardNo", "todito_pin"],
    "ToditoCash-Todito": [...form2, "todito_cardNo", "todito_pin"],
    "Wallet-AlipayHK": ["name", "email", "phone"],
    "Wallet-AlipayCN": [],
    "Wallet-GCash": ["name", "email", "phone"],
    "Wallet-Paymaya": ["name", "email", "phone"],
    "Wallet-GrabPay": ["name", "email", "phone"],
    "Wallet-OVO": ["name", "email", "phone"],
    "Wallet-PayNow": ["name", "email", "phone"],
    "Wallet-RabbitLinePay": ["name", "email", "phone"],
    "Wallet-BPI": ["name", "email", "phone"],
    "Wallet-MADA": ["name", "email", "phone"],
    "Wallet-KNet": ["name", "email", "phone"],
    "Wallet-FawryPay": ["name", "email", "phone"],
    "Cash-FawryPay": ["name", "email", "phone"],
    "Wallet-QIWI": ["phone"],
    "Wallet-Mercadopago": form2,
    "CoDi-STP": ["name", "email", "phone"],
    "CreditCard-SHAREitPay": ["name", "email", "phone"],
    "GCash-SHAREitPay": ["name", "email", "phone"],
    "UPI-SHAREitPay": ["name", "email", "phone"],
    "UPI-Tazapay": ["firstName", "lastName", "email"],
    "NetBanking-SHAREitPay": ["name", "email", "phone"],
    "PiPay-PiPay": ["name", "email", "phone"],
    "PayGo-EDEE": ["name", "email", "phone"],
    "AlfaClick-Payssion": ["name", "email", "phone"],
    "Qiwi-Payssion": ["name", "email", "phone"],
    "WebMoney-Payssion": ["name", "email", "phone"],
    "YandexMoney-Payssion": ["name", "email", "phone"],
    "UnionPay-Payssion": ["name", "email", "phone"],
    "Wallet-YooMoney": ["name", "email", "phone"],
    "DirectDebit-Xendit": ["firstName", "lastName", "email", "phone"],
    "OTC-Xendit": ["firstName", "lastName", "email", "phone"],
    "Wallet-GcashXendit": ["name", "email", "phone"],
    "Wallet-KakaoPayAlipay": ["name", "email", "phone"],
    "Wallet-DANA": ["name", "email", "phone"],
    "AlipayPlus-AlipayPlus": ["name", "email", "phone"],
    "Wallet-TNG": ["name", "email", "phone"],
    "Wallet-BOOST": ["name", "email", "phone"],
    "Wallet-TrueMoney": ["name", "email", "phone"],
    "Wallet-NaverPay": ["name", "email", "phone"],
    "Wallet-Vita": form2,
    "Wallet-Chek": form2,
    "Wallet-Mach": form2,
    "Wallet-TPaga": form2,
    "Wallet-PicPay": form2,
    "Wallet-AME": form2,
    "Wallet-Eprepag": ["email"],
    "Wallet-MOL": form2,
    "Wallet-ClaroPay": form2,
    "Wallet-Daviplata": [...form2, "address"],
    "Wallet-Rappipay": [...form2, "address"],
    "Wallet-Dale": [...form2, "address"],
    "Wallet-Movii": [...form2, "address"],
    "Wallet-Nequi": [...form2, "address"],
    "PSE-ACH": [...form2, "address", "bank_list"],
    "Wallet-Paysafecard": ["email"],
    "Wallet-Skrill": ["email"],
    "Wallet-KakaoPay": ["name", "email", "phone"],
    "Wallet-TOSS": ["name", "email", "phone"],
    "Wallet-PAYCO": ["name", "email", "phone"],
    "Wallet-SamsungPay": ["name", "email", "phone"],
    "Wallet-Fpay": ["name", "email", "phone"],
    "CreditCard-Payletter": ["name", "email", "phone"],
    "BankTransfer-Payletter": ["name", "email", "phone"],
    "VirtualAccount-Payletter": ["name", "email", "phone"],
    "BCR-PayValida": ["name", "email", "phone"],
    "BNCR-PayValida": ["name", "email", "phone"],
    "BNCR-PayCash": ["name", "email", "phone"],
    "Boleto-BS2": [...form2, "boleto_cep"],
    "Boleto-StarkBank": [...form2, "boleto_cep"],
    "BoletoRapido-BS2": [...form2, "boleto_cep"],
    "Boleto-Mercadopago": [...form2, "boleto_cep"],
    "Boleto-CHANNEL_GROUP_ROUTER": [...form2, "boleto_cep"],
    "Lottery-Caixa": form2,
    "Lottery-Mercadopago": form2,
    "PIX-BS2": ["name", "taxId", "email"],
    "PIX-Bradesco": ["name", "taxId", "email"],
    "PIX-Rendimento": ["name", "taxId", "email"],
    "PIX-Nupay": ["name", "taxId", "email"],
    "PIX-Genial": ["name", "taxId", "email"],
    "PIX-StarkBank": ["name", "taxId", "email"],
    "PIX-StarkBankTransferSmile": ["name", "taxId", "email"],
    "PIX-CHANNEL_GROUP_ROUTER": ["name", "taxId", "email"],
    "PIX-Mercadopago": ["name", "taxId", "email"],
    "PIX-BTG": ["name", "taxId", "email"],
    "PIX-Fastcash": ["name", "taxId", "email"],
    "PIX-Delbank": ["name", "taxId", "email"],
    "PIX-Pagsmile": ["name", "taxId", "email"],
    "PlayersBankPIX-BS2": form2,
    "BankTransfer-SafetyPay": form2,
    "BankTransfer-ETpay": form2,
    "Cash-SafetyPay": ["name", "email", "phone"],
    "Khipu-Khipu": form2,
    "CreditCard-Payku": form2,
    "Pago46-Payku": form2,
    "WebPay-Payku": form2,
    "KLAP-Multicaja": form2,
    "Cash-Multicaja": form2,
    "BankTransfer-Multicaja": form2,
    "PSE-Payty": ["taxId"],
    "PSE-PayValida": ["email"],
    "Efecty-Payty": form2,
    "Efecty-PaytyV2": form2,
    "PSE-PaytyV2": ["taxId"],
    "SPEI-OpenPay": form2,
    "SPEI-STP": form2,
    "SPEI-Banwire": form2,
    "OXXOPay-Conekta": ["name", "email", "phone"],
    "OXXO-Banwire": ["name", "email", "phone"],
    "OXXO-CardPay": ["name", "email", "phone"],
    "OXXO-Bamboo": ["name", "email", "phone"],
    "OXXO-Localpayment": ["name", "email", "phone"],
    "Cash-OpenPay": form3,
    "Cash-Banwire": form3,
    "Baloto-PayValida": form2,
    "SuRed-PayValida": form2,
    "GANA-PayValida": form2,
    "Bancolombia-PayValida": form2,
    "Baloto-IGT": form2,
    "DepositExpress-Levpay": form2,
    "BankTransfer-PagoEfectivo": form2,
    "PagoEfectivo-PagoEfectivo": form2,
    "Cash-PagoEfectivo": form2,
    "Cash-PayCash": ["name", "email", "phone"],
    "Wallet-Fawry": ["name", "email", "phone"],
    "Wallet-AstroPay": ["name", "email", "phone"],
    "Wallet-PaypalXCG": ["email"],
    "PagoFacil-Localpayment": form2,
    "PagoFacil-PagoFacil": form2,
    "Rapipago-Localpayment": form2,
    "Rapipago-Rapipago": form2,
    "Servipag-Localpayment": form2,
    "BankTransfer-Localpayment": [...form2, "account_number", "bank_list"],
    "Cash-Localpayment": [...form2, "bank_list"],
    "CreditCard-Localpayment": [...form2, "cardNumber", "expYear", "expMonth", "cvc"],
    "DebitCard-Localpayment": [...form2, "cardNumber", "expYear", "expMonth", "cvc"],
    "CreditCard-Movii": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-PGOne": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-Izipay": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-IzipaySmileone": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-Adiq": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-Bamboo": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-Multicaja": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "Sofort-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "Trustly-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "Trustly-PPRO": ["firstName", "lastName", "email", "country"],
    "Neosurf-Emerchantpay": ["voucher_number", "firstName", "lastName", "email", "phone"],
    "Bancontact-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "EPS-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "Giropay-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "iDeal-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "MyBank-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "P24-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "SberBank-YooMoney": ["name", "email", "phone"],
    "Transfiya-Movii": ["name", "email", "phone"],
    "BankTransfer-Monnet": ["firstName", "lastName", "email", "phone", "taxId"],
    "Cash-Monnet": ["firstName", "lastName", "email", "phone", "taxId"],
    "BankTransfer-KASHIO": ["firstName", "lastName", "email", "phone", "taxId"],
    "Cash-KASHIO": ["firstName", "lastName", "email", "phone", "taxId"],
    "Wallet-Tupay": ["firstName", "lastName", "email", "phone", "taxId"],
    "Giropay-PPRO": ["firstName", "lastName", "email", "phone"],
    "CreditCard-Mercadopago": ["name", "email", "taxId"],
    "Crypto-PGOne": ["email"],
    "Binance-PGOne": ["email"],
    "OpenFinance-Mercadopago": ["name", "taxId", "email", "bank_list"],
    "OpenFinance-Iniciador": ["name", "taxId", "email", "bank_list"],
    "CreditCard-Kushki": ["name", "cardNumber", "expYear", "expMonth", "cvc", "phone", "taxId"],
    "Paysafecard-PPRO": ["name", "country"],
    "Multibanco-PPRO": ["name", "country"],
    "Payconiq-PPRO": ["name", "country"],
    "BankTransfer-HappyPay": ["bank_list"],
    "OTC-HappyPay": ["bank_list"],
    "Wallet-LINEPay": [],
    "Wallet-Papara": ["email"],
    "Wallet-ShopeePay": [],
    "SBP-PGOne": ["phone"],
    "SberPay-PGOne": [],
  };

  const formConfig2 = {
    "BRA-CreditCard-Paypal": ["taxId", "email", "phone"],
    "BRA-RecurringCreditCard-Paypal": ["taxId", "email", "phone"],
    "BRA-PlayersBankCard-Paypal": ["taxId", "email", "phone"],
    "BRA-Wallet-Paypal": form2,
    "MEX-Wallet-Paypal": [],
  };

  const formConfig3 = {
    "1183-CreditCard-PGOne": ["name", "cardNumber", "expYear", "expMonth", "cvc"], // bank131Direct
  };

  let config =
    formConfig3[providerId + "-" + method + "-" + channel] ||
    formConfig2[region + "-" + method + "-" + channel] ||
    formConfig[method + "-" + channel] ||
    [];

  if (isAddressNeeded(method, channel, region)) {
    if (isAddressSame) {
      config = [...config, "bill_country", "bill_state", "bill_city", "bill_street", "bill_no", "bill_postalCode"];
    } else {
      config = [
        ...config,
        "bill_country",
        "bill_state",
        "bill_city",
        "bill_street",
        "bill_no",
        "bill_postalCode",
        "delivery_country",
        "delivery_state",
        "delivery_city",
        "delivery_street",
        "delivery_no",
        "delivery_postalCode",
      ];
    }
  }

  return config;
}
