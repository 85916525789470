import { getFingerPrint } from "./getFingerPrint";
import req from "./axios";
import { getGoogleRecaptchaId, isProduction } from "../envs/env";
import uuid from "react-uuid";

const loadCrediMaxLib = (successCallback = () => {}, prepayId, method, multiRegion) => {
  let postUrl = `/api/trade/get-client-token?prepay_id=${prepayId}&method=${method}`;
  if (multiRegion) postUrl += `&region=${multiRegion}`;
  req.post(postUrl).then((res) => {
    if (res?.data?.code === "10000") {
      let name = "CreditCard-CrediMax";
      const existingScript = document.getElementById(name);
      if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://credimax.gateway.mastercard.com/form/version/${res?.data?.data?.version}/merchant/${res?.data?.data?.merchant_id}/session.js`;
        //script.id = name;
        document.body.appendChild(script);
        script.onload = () => {
          successCallback();
          sessionStorage?.setItem("CrediMax__session", res?.data?.data?.token);
        };
      }
    }
  });
};

const loadAdiqFingerPrint = (successCallback = () => {}) => {
  const guuid = uuid();
  window.adiq_br_uuid = guuid;
  const src = `https://h.online-metrix.net/fp/tags.js?org_id=${
    isProduction() ? "k8vif92e" : "1snn5n9w"
  }&session_id=adiq_br${guuid}`;

  loadScript(src, "Adiq-FingerPrint-1").then(() => {
    const existingIframe = document.getElementById("Adiq-FingerPrint-2");
    if (existingIframe) {
      successCallback();
    } else {
      const iframe = document.createElement("iframe");
      iframe.id = "Adiq-FingerPrint-2";
      iframe.src = src;
      iframe.style = "width: l00px; height: l00px; border: O; position:absolute; top: -5000px;";
      document.body.appendChild(iframe);
      iframe.onload = () => {
        successCallback();
      };
    }
  });
};

const libs = {
  "CreditCard-Kushki": [getFingerPrint, `${window.location.origin}/public/inboundlibs/kushki/kushki.min.js`],
  "CreditCard-Adiq": [
    getFingerPrint,
    `${window.location.origin}/public/inboundlibs/jquery/v3.3.1.min.js`,
    `${window.location.origin}/public/inboundlibs/adiq/${
      isProduction() ? "adiq-3ds-package-1.0.1-min.js" : "adiq-3ds-package-hml-1.0.1-min.js"
    }`,
    loadAdiqFingerPrint,
  ],
  "CreditCard-Mercadopago": [getFingerPrint, `${window.location.origin}/public/inboundlibs/mp/mp-v2.js`],
  "CreditCard-OpenPay-MEX": [
    `${window.location.origin}/public/inboundlibs/jquery/v1.11.0.min.js`,
    `${window.location.origin}/public/inboundlibs/openpay/MEX/openpay-mx.v1.min.js`,
    `${window.location.origin}/public/inboundlibs/openpay/MEX/openpay-mx-data.v1.min.js`,
    getFingerPrint,
  ],
  "CreditCard-OpenPay-EUP": [
    `${window.location.origin}/public/inboundlibs/jquery/v1.11.0.min.js`,
    `${window.location.origin}/public/inboundlibs/openpay/MEX/openpay-mx.v1.min.js`,
    `${window.location.origin}/public/inboundlibs/openpay/MEX/openpay-mx-data.v1.min.js`,
    getFingerPrint,
  ],
  "CreditCard-OpenPay-PER": [
    `${window.location.origin}/public/inboundlibs/jquery/v1.11.0.min.js`,
    `${window.location.origin}/public/inboundlibs/openpay/PER/openpay-pe.v1.min.js`,
    `${window.location.origin}/public/inboundlibs/openpay/PER/openpay-pe-data.v1.min.js`,
    getFingerPrint,
  ],
  "CreditCard-Xendit": [`${window.location.origin}/public/inboundlibs/xendit/v1-xendit.min.js`, getFingerPrint],
  "Wallet-Paypal": [
    `${window.location.origin}/public/inboundlibs/paypal/wallet/v3.87.0-client.min.js`,
    `${window.location.origin}/public/inboundlibs/paypal/wallet/v3.87.0-paypal-checkout.min.js`,
    `${window.location.origin}/public/inboundlibs/paypal/wallet/v3.87.0-data-collector.min.js`,
  ],
  "Wallet-Paypal-MEX": [
    `https://www.paypal.com/sdk/js?client-id=${
      isProduction()
        ? "AX7BZuLZWpwnuTSR6Q-UYqTIGSixHfSkQtukoo1-BMmFojO_uPIkebJlwTSHhhV_-0gmyJNuIHYxjgDf"
        : "AakxbO-9ZWU1oghlFDlJIXSDGr_MBO2k8Sd7bhPBQGsYDBCfraX5_sBnnKB_4RXedLsAfaZWHlSd0FKL"
    }&currency=MXN&disable-funding=card`,
  ],
  "CreditCard-Paypal": [getFingerPrint],
  "PlayersBankCard-Paypal": [getFingerPrint],
  "RecurringCreditCard-Paypal": [getFingerPrint],
  "CreditCard-E-Comprocessing": [getFingerPrint],
  "CreditCard-CrediMax": [getFingerPrint, loadCrediMaxLib],
  "CreditCard-CardPay": [getFingerPrint],
  "CreditCard-Movii": [getFingerPrint],
  "CreditCard-Izipay": [getFingerPrint],
  "CreditCard-IzipaySmileone": [getFingerPrint],
  "CreditCard-Multicaja": [getFingerPrint],
  "CreditCard-Localpayment": [getFingerPrint],
  "DebitCard-Localpayment": [getFingerPrint],
  "CreditCard-Bamboo": [getFingerPrint],
  "CreditCard-PGOne": [getFingerPrint],
};

const loadedScripts = [];
const loadScript = (item, name, prepayId, method, multiRegion) => {
  return new Promise((resolve, reject) => {
    if (typeof item === "function") {
      const existingScript = loadedScripts.includes(item.name);
      if (!existingScript) {
        loadedScripts.push(item.name);
        item(
          () => {
            resolve();
          },
          prepayId,
          method,
          multiRegion
        );
      } else {
        resolve();
      }
    } else {
      const existingScript = loadedScripts.includes(item); // document.getElementById(name) ;
      if (!existingScript) {
        loadedScripts.push(item);
        const script = document.createElement("script");
        script.src = item;
        script.id = name;
        document.body.appendChild(script);
        script.onload = () => {
          // console.log(name);
          resolve();
        };
        script.onerror = () => {
          reject();
        };
      } else {
        resolve();
      }
    }
    // console.log("loadedScripts: ", name, JSON.stringify(loadedScripts));
  });
};

async function loadOneAfterAnother(arr, name, prepayId) {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    await loadScript(item, name + i, prepayId);
    // console.log(`${name} : ${i}`);
  }
}

const googleRecaptchaSrc = `https://www.google.com/recaptcha/enterprise.js?render=${getGoogleRecaptchaId()}`;
const optionalRecaptchaConfig = ["CreditCard", "RecurringCreditCard", "DebitCard", "PlayersBankCard"];

export const loadLibs = ({ channels, region, setLibs = () => {}, excludedLibs = {}, prepayId, isMultiRegion }) => {
  try {
    channels?.forEach((channel) => {
      // optional googleRecaptcha
      if (optionalRecaptchaConfig.includes(channel.method)) {
        loadScript(googleRecaptchaSrc, "google-recaptcha")
          .then()
          .catch(() => {
            console.log("google-recaptcha loading error!");
          });
      }

      let name = channel.method + "-" + channel.channel;
      if (name === "Wallet-Paypal" && region === "MEX") {
        name += `-${region}`;
      }

      if (
        (name === "CreditCard-Paypal" || name === "RecurringCreditCard-Paypal" || name === "PlayersBankCard-Paypal") &&
        channel.need_threeds
      ) {
        libs[name] = [
          ...libs[name],
          `${window.location.origin}/public/inboundlibs/paypal/creditcard/v3.87.0-three-d-secure.min.js`,
        ];
      }

      if (name === "CreditCard-OpenPay" && !excludedLibs[name]) {
        loadOneAfterAnother(libs[name + "-" + region], name, prepayId)
          .then(() => {
            setLibs({
              [name]: true,
            });
          })
          .catch(() => {
            setLibs({
              [name]: false,
            });
          });
      } else if (name === "CreditCard-Adiq" && !excludedLibs[name]) {
        loadOneAfterAnother(libs[name], name, prepayId)
          .then(() => {
            setLibs({
              [name]: true,
            });
          })
          .catch(() => {
            setLibs({
              [name]: false,
            });
          });
      } else {
        if (libs[name] && !excludedLibs[name]) {
          let count = 0;
          libs[name]?.forEach((item, index) => {
            loadScript(item, name + index, prepayId, channel.method, isMultiRegion && region)
              .then(() => {
                // console.log("count: >>>>> ", `${index} : ${count}`);
                count += 1;
                setLibs({
                  [name]: libs[name].length === count,
                });
              })
              .catch(() => {
                console.log(`${name + index} loadLibs Error!`);
              });
          });
        }
      }
    });
  } catch {
    console.error("loadLibs Error!");
  }
};

export const loadLib = (method, channel, setLibs = () => {}) => {
  return new Promise((resolve, reject) => {
    let name = method + "-" + channel;
    if (libs[name]) {
      let count = 0;
      libs[name]?.forEach((item, index) => {
        loadScript(item, name + index)
          .then(() => {
            // console.log("count: >>>>> ", `${index} : ${count}`);
            count += 1;
            setLibs({
              [name]: libs[name].length === count,
            });
            libs[name].length === count && resolve();
          })
          .catch(() => {
            reject();
          });
      });
    }
  });
};

export const setLiveChat = (data) => {
  //live-chat
  window.__lc = window.__lc || {};
  window.__lc.license = 8514118;
  window.__lc.chat_between_groups = false;
  window.__lc.group = 23;
  window.__lc.params = [
    { name: "app Name", value: data?.app_info?.app_name },
    { name: "Trade NO.", value: data?.out_trade_no },
    { name: "Order Amount", value: data?.order_amount },
  ];
  var lc = document.createElement("script");
  lc.type = "text/javascript";
  lc.async = true;
  lc.src = ("https:" == document.location.protocol ? "https://" : "http://") + "cdn.livechatinc.com/tracking.js";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(lc, s);
  //end live-chat
};
