import React from "react";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js/mobile";
import { countryCode3to2, latinCountries, formatCurrency } from "./configurations";
import Visa from "../img/visa.png";
import Elo from "../img/elo.png";
import Master from "../img/master.png";
import Hiper from "../img/hiper.png";
import Amex from "../img/amex.png";
import Diners from "../img/diners_club.png";
import uuid from "react-uuid";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import country2codes from "./regionCodes";

const mapStateToProps = (state, ownprops) => {
  return {
    paymentInfo: state.paymentInfo,
    tradeInfo: state.tradeInfo,
    payData: state.payData,
    method: state.method,
    payChannel: state.payChannel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export const FormatMoney = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ type, data, ...props }) => {
  var value = data || "";
  var currency = "";
  var installment = null;

  if (type === "order") {
    value = props?.paymentInfo?.order_amount || props?.tradeInfo?.order_amount;
    if (props.method === "RecurringCreditCard" && props.payChannel === "Paypal") {
      value = props.tradeInfo.recurring?.trial_amount;
    }
    currency = props?.paymentInfo?.order_currency || props?.tradeInfo?.order_currency;
  } else if (type === "total") {
    installment = props?.paymentInfo?.installment || (props?.tradeInfo?.stage > 1 && props?.tradeInfo?.stage_detail);
    value = props?.payData?.amount || props?.paymentInfo?.pay_amount || props?.tradeInfo?.pay_amount;
    currency = props?.paymentInfo?.pay_currency || props?.tradeInfo?.pay_currency;

    // Temporarily case for CreditCard-Paypal which is under Global region
    if (props.method === "CreditCard" && props.payChannel === "Paypal" && props.tradeInfo?.app_info?.region === "GLB") {
      let orderValue = Number(props?.paymentInfo?.order_amount || props?.tradeInfo?.order_amount);
      value = orderValue + (orderValue / 100) * 4;
      // console.log("orderValue>>>>>>>>", orderValue, typeof orderValue);
      currency = props?.paymentInfo?.order_currency || props?.tradeInfo?.order_currency;
    }
  } else if (type === "discount") {
    value = props?.paymentInfo?.fees?.discountable_sum;
    currency = props?.paymentInfo?.pay_currency || props?.tradeInfo?.pay_currency;
  } else if (type === "fee") {
    currency = props?.paymentInfo?.pay_currency || props?.tradeInfo?.pay_currency;
  }

  var reverse = false;
  if (latinCountries.indexOf(props.tradeInfo?.app_info?.region) >= 0) {
    reverse = true;
    value = String(value).replace(".", ",");
    if (installment) {
      installment.amount_each = String(installment.amount_each).replace(".", ",");
    }

    // value =
    //   value &&
    //   String(value).replace(/\.|,/g, function (m) {
    //     // `m` is a matched string.
    //     return m === "." ? "," : ".";
    //   });
  }

  return (
    <>
      <NumberFormat
        value={installment ? installment.amount_each : value}
        displayType={"text"}
        thousandSeparator={reverse ? "." : ","}
        decimalSeparator={reverse ? "," : "."}
        decimalScale={2}
        fixedDecimalScale
        prefix={`${formatCurrency[currency] || currency} `}
        suffix={installment ? ` x ${installment.stage}` : ""}
        style={{ whiteSpace: "nowrap" }}
      />
    </>
  );
});

const formatUnknownPhone = (number, code) => {
  const asYouType = new AsYouType(code);
  asYouType.input(number);
  // console.log("asYouType getNumber: ", asYouType?.getNumber?.());
  return asYouType?.getNumber?.()?.number ?? number; // number with international format without beautify, +5511987654321
};

export const formatPhone = (number, code, unknown) => {
  code = String(code ?? "").toUpperCase();
  // number = number.replace(/[^\d\+]/g, "");
  if (code.length === 3) {
    code = countryCode3to2[code] ?? "";
  }
  // By default, if a value is something like `"(123)"`
  // then Backspace would only erase the rightmost brace
  // becoming something like `"(123"`
  // which would give the same `"123"` value
  // which would then be formatted back to `"(123)"`
  // and so a user wouldn't be able to erase the phone number.
  // Working around this issue with this simple hack.

  if (number.length < 3) {
    return number;
  } else {
    const asYouType = new AsYouType(code); // here we beautify it
    return asYouType.input(unknown ? formatUnknownPhone(number, code) : number); // +55 75 99143 5892
  }
};

export const hidedCardNo = (no) => {
  return String(no)
    .replace(/[^\d]/g, "")
    .replace(/^(\d{6})(\d*)(\d{4})$/, function (a, b, c, d) {
      return b + c.replace(/\d/g, "*") + d;
    });
};

const code2Regx = {
  MEX: {
    RFC: /^([a-zA-Z]{1,4})(\d{0,6})([a-zA-Z0-9]{0,3})/,
    CURP: /^([a-zA-Z]{1,4}\d{0,6}[a-zA-Z]{0,6}[a-zA-Z0-9]{0,1}\d{0,1})/,
  },
  CHL: { RUT: /^(\d{1,8})([0-9Kk]{0,1})/, RUN: /^(\d{1,8})([0-9Kk]{0,1})/ },
  COL: { NIT: /^(\d{1,11})/, CC: /^(\d{1,10})/, CE: /^([a-zA-Z0-9]{1,12})/ },
  PER: { DNI: /^(\d{1,8})/, RUC: /^(\d{1,11})/ },
  ECU: { RUC: /^(\d{1,13})/, CE: /^(\d{1,10})/, CI: /^(\d{1,10})/ },
  BRA: {
    CPF: /^(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,2})/,
    CNPJ: /^(\d{1,2})(\d{0,3})(\d{0,3})(0{0,3}[12]{0,1})(\d{0,2})/,
  },
  PAN: { CIP: /^(\d{1})(\d{0,3})(\d{0,2})/ },
  CRI: { CDI: /^(\d{1})(\d{0,4})(\d{0,4})/ },
  SLV: { DUI: /^(\d{1,8})(\d{0,1})/ },
  GTM: { DPI: /^(\d{1,8})(\d{0,1})/ },
  NIC: { CIP: /^(\d{1})(\d{0,3})(\d{0,2})/ },
  ARG: {
    DNI: /^(\d{1,2})(\d{0,3})(\d{0,3})/,
    CUIT: /^(\d{1,2})(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,1})/,
    CUIL: /^(\d{1,2})(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,1})/,
    CDI: /^(\d{1,2})(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,1})/,
  },
  URY: {
    CI: /^(\d{1})(\d{0,3})(\d{0,3})(\d{0,1})/,
    RUT: /^(\d{1,2})(\d{0,6})(\d{0,3})(\d{0,1})/,
  },
  BOL: {
    NIT: /^(\d{1,12})/,
    CI: /^([a-zA-Z0-9]{1,2})(\d{0,5})([a-zA-Z0-9]{0,2})/,
    CE: /^(\d{1,8})/,
  },
  PRY: {
    RUC: /^(\d{1,9})/,
    CI: /^(\d{1,7})/,
  },
};

export const formatTaxId = (data, code, idType) => {
  var match;
  var cleared = data?.replace(/[^a-zA-Z0-9]/g, "");

  match = cleared?.match(code2Regx[code]?.[idType]);
  // console.log(data, cleared, code2Regx[code]?.[idType], match);

  if (match) {
    if (code === "BRA") {
      if (idType === "CPF") {
        return (
          match[1] +
          (match[2] ? "." + match[2] : "") +
          (match[2] && match[3] ? "." + match[3] : "") +
          (match[2] && match[3] && match[4] ? "-" + match[4] : "")
        );
      } else if (idType === "CNPJ") {
        return (
          match[1] +
          (match[2] ? "." + match[2] : "") +
          (match[2] && match[3] ? "." + match[3] : "") +
          (match[2] && match[3] && match[4] ? "/" + match[4] : "") +
          (match[2] && match[3] && match[4] && match[5] ? "-" + match[5] : "")
        );
      }
    } else if (code === "MEX") {
      if (idType === "RFC") {
        return match[1] + (match[2] ? "-" + match[2] : "") + (match[2] && match[3] ? "-" + match[3] : "");
      } else if (idType === "CURP") {
        return match[1];
      }
    } else if (code === "CHL") {
      return match[1] + (match[2] ? "-" + match[2] : "");
    } else if (code === "COL") {
      return match[1];
    } else if (code === "PER") {
      return match[1];
    } else if (code === "ECU") {
      return match[1];
    } else if (code === "PAN") {
      return match[1] + (match[2] ? "-" + match[2] : "") + (match[2] && match[3] ? "-" + match[3] : "");
    } else if (code === "CRI") {
      return match[1] + (match[2] ? "-" + match[2] : "") + (match[2] && match[3] ? "-" + match[3] : "");
    } else if (code === "SLV") {
      return match[1] + (match[2] ? "-" + match[2] : "");
    } else if (code === "GTM") {
      return match[1] + (match[2] ? "-" + match[2] : "");
    } else if (code === "NIC") {
      return match[1] + (match[2] ? "-" + match[2] : "") + (match[2] && match[3] ? "-" + match[3] : "");
    } else if (code === "ARG") {
      if (idType === "DNI") {
        return match[1] + (match[2] ? "." + match[2] : "") + (match[2] && match[3] ? "." + match[3] : "");
      } else if (idType === "CUIT" || idType === "CUIL" || idType === "CDI") {
        return (
          match[1] +
          (match[2] ? "-" + match[2] : "") +
          (match[2] && match[3] ? "." + match[3] : "") +
          (match[2] && match[3] && match[4] ? "." + match[4] : "") +
          (match[2] && match[3] && match[4] && match[5] ? "-" + match[5] : "")
        );
      }
    } else if (code === "URY") {
      if (idType === "CI") {
        return (
          match[1] +
          (match[2] ? "." + match[2] : "") +
          (match[2] && match[3] ? "." + match[3] : "") +
          (match[2] && match[3] && match[4] ? "-" + match[4] : "")
        );
      } else if (idType === "RUT") {
        return (
          match[1] +
          (match[2] ? "-" + match[2] : "") +
          (match[2] && match[3] ? "-" + match[3] : "") +
          (match[2] && match[3] && match[4] ? "-" + match[4] : "")
        );
      }
    } else if (code === "BOL") {
      if (idType === "CI") {
        return match[1] + (match[2] ? match[2] : "") + (match[2] && match[3] ? match[3] : "");
      }
      return match[1];
    } else if (code === "PRY") {
      return match[1];
    }
  }
  return "";
};

var reg_visa = /^(4)/; // visa
var reg_master = /^(5|(2(221|222|223|224|225|226|227|228|229|23|24|25|26|27|28|29|3|4|5|6|70|71|720)))/; // master
var reg_elo =
  /^((50670[7-8])|506715|(50671[8-9])|(50672[0-1])|(50672[4-9])|(50673[0-3])|506739|(50674[1-3])|(50674[5-7])|506753|(50677[4-8])|(50900[0-2])|(50900[4-7])|509009|(50901[0-2])|509014|(50902[0-9])|509030|(50903[5-9])|(50904[0-2])|(50904[4-9])|(50905[0-9])|(50906[0-4])|(50906[6-9])|(50907[0-2])|(50907[4-9])|(50908[0-9])|(50909[1-2])|(50909[5-9])|(50910[0-1])|(50910[6-9])|(50911[0-9])|(50912[0-9])|(50913[0-9])|(50914[0-9])|(50915[0-9])|(50916[0-9])|(50917[0-9])|(50918[0-9])|(50919[0-9])|(50920[0-9])|(50921[0-9])|(50922[0-9])|(50923[0-9])|(50924[0-9])|(50925[0-9])|(50926[0-9])|(50927[0-9])|(50928[0-9])|(50929[0-9])|(50930[0-9])|(50931[0-9])|(50932[0-9])|(50933[0-9])|(50934[0-9])|(50935[0-9])|(50936[0-9])|(50937[0-9])|(50938[0-9])|(50939[0-9])|(50940[0-9])|(50941[0-9])|(50942[0-9])|(50943[0-9])|(50944[0-9])|(50945[0-9])|(50946[0-9])|(50947[0-9])|(50948[0-9])|(50949[0-9])|(50950[0-9])|(50951[0-9])|(50952[0-9])|(50953[0-9])|(50954[0-9])|(50955[0-9])|(50956[0-9])|(50957[0-9])|(50958[0-9])|(50959[0-9])|(50960[0-9])|(50961[0-9])|(50962[0-9])|(50963[0-9])|(50964[0-9])|(50965[0-9])|(50966[0-9])|(50967[0-9])|(50968[0-9])|(50969[0-9])|(50970[0-9])|(50971[0-9])|(50972[0-9])|(50973[0-9])|(50974[0-9])|(50975[0-9])|(50976[0-9])|(50977[0-9])|(50978[0-9])|(50979[0-9])|(50980[0-7])|(50983[1-9])|(50984[0-9])|(50985[0-9])|(50986[0-9])|(50987[0-7])|(50989[7-9])|509900|(50991[8-9])|(50992[0-9])|(50993[0-9])|(50994[0-9])|(50995[0-9])|(50996[0-4])|(50997[1-9])|(50998[0-6])|(50999[5-9])|636368|(65040[6-9])|(65041[0-9])|(65042[0-9])|(65043[0-9])|(65048[5-9])|(65049[0-9])|(65050[0-4])|(65050[6-9])|(65051[0-9])|(65052[0-9])|(65053[0-8])|(65055[2-9])|(65056[0-9])|(65057[0-9])|(65058[0-9])|(65059[0-8])|(65072[0-7])|(65090[1-9])|(65091[0-9])|(65092[0-2])|650928|650939|(65094[6-9])|(65095[0-9])|(65096[0-9])|(65097[0-8])|(65165[2-9])|(65166[0-9])|(65167[0-9])|(65168[0-9])|(65169[0-9])|(65170[0-4])|(65500[0-9])|(65501[0-9])|(65502[1-9])|(65503[0-9])|(65504[0-9])|(65505[0-7]))/; // elo
var reg_hipercard = /^((606282)|(637095)|(637568)|(637599)|(637609)|(637612))/; // hipercard
var reg_amex = /^((34)|(37))/; // amex
var reg_diners = /^(30[0-5])|36|38/; // diners club

export var issuer = "";

export function getBankPaymentIcon(data) {
  var bin6 = String(data).replace(/[ ]/g, "").slice(0, 6);
  if (bin6.length === 6) {
    if (reg_visa.test(bin6)) {
      issuer = "visa";
      return Visa;
    } else if (reg_hipercard.test(bin6)) {
      issuer = "hipercard";
      return Hiper;
    } else if (reg_amex.test(bin6)) {
      issuer = "amex";
      return Amex;
    } else if (reg_elo.test(bin6)) {
      issuer = "elo";
      return Elo;
    } else if (reg_master.test(bin6)) {
      issuer = "master";
      return Master;
    } else if (reg_diners.test(bin6)) {
      issuer = "diners";
      return Diners;
    }
  } else {
    issuer = "";
  }
  return "";
}

export const formatCreditCardNumber = (data) => {
  getBankPaymentIcon(data);
  const regex =
    issuer === "diners" ? /^([\d]{1,4})([\d]{0,6})([\d]{0,4})/ : /^([\d]{1,4})([\d]{0,4})([\d]{0,4})([\d]{0,5})/;

  let match = data?.replace(/[^\d]/g, "")?.match(regex);

  if (match) {
    if (issuer === "diners") {
      return match[1] + (match[2] ? " " + match[2] : "") + (match[3] ? " " + match[3] : "");
    } else {
      return (
        match[1] +
        (match[2] ? " " + match[2] : "") +
        (match[3] ? " " + match[3] : "") +
        (match[4] ? " " + match[4] : "")
      );
    }
  } else {
    return "";
  }
};

export const formatMachCardNumber = (data) => {
  let match = data?.replace(/[^\d]/g, "")?.match(/^([\d]{1,4})([\d]{0,4})([\d]{0,4})([\d]{0,4})/);

  if (match) {
    return (
      match[1] + (match[2] ? " " + match[2] : "") + (match[3] ? " " + match[3] : "") + (match[4] ? " " + match[4] : "")
    );
  } else {
    return "";
  }
};

export const formatCEP = (data) => {
  let match = data?.replace(/[^\d]/g, "")?.match(/^([\d]{1,5})([\d]{0,3})/);

  if (match) {
    return match[1] + (match[2] ? "-" + match[2] : "");
  } else {
    return "";
  }
};

export const formatChannelList = (data) => {
  try {
    return data?.reduce((formatted, item) => {
      let exist = formatted?.some((obj, index) => {
        if (item.bank_id === obj?.data[0]?.bank_id) {
          formatted[index].data.push(item);
        }
        return item.bank_id === obj?.data[0]?.bank_id;
      });

      !exist && formatted.push({ data: [item], key: uuid() });

      return formatted;
    }, []);
  } catch {
    console.error("formatChannelList error!");
    return [];
  }
};

export const formatMethod = (data, method = "", channel = "") => {
  let methods = [];
  try {
    const cryptoTipleA = data?.find((item) => item.channel === "PGOne" && item.method === "Crypto");
    if (cryptoTipleA) {
      data.push({
        originalMethod: "Crypto",
        channel: "PGOne",
        method: "Binance",
        sort: cryptoTipleA.sort - 1 || 0,
      });
    }
    let sortedUniqueMethods = data
      .sort((a, b) => a?.sort - b?.sort)
      .filter((item, i) => {
        methods.push(item.method);
        return methods.indexOf(item.method) === i;
      });
    return sortedUniqueMethods;
  } catch {
    console.error("formatMethod error!");
    return [];
  }
};

export const formatPostalCode = (data, code, country, isGlobal = false) => {
  let regExp = /^([A-Z\d- ]{1,10})/; // general regExp
  let cCodeObj = "";
  let val = String(data ?? "").toUpperCase();

  if (isGlobal && country) {
    cCodeObj = country2codes.find((item) => item.country_name === country);
  } else {
    cCodeObj = country2codes.find((item) => item.alpha3_code === code);
  }

  code = cCodeObj?.alpha3_code ?? code;

  if (cCodeObj?.postal_format) {
    val = val.replace(/[^A-Z\d]/, "");
    regExp = cCodeObj.postal_format;
  }
  // console.log("regExp >> ", regExp);
  let match = val.match(regExp);
  if (match) {
    if (
      code === "BRA" ||
      code === "JPN" ||
      code === "AIA" ||
      code === "LTU" ||
      code === "MDA" ||
      code === "POL" ||
      code === "PRT" ||
      code === "VEN" ||
      code === "SAU" ||
      code === "TWN" ||
      code === "GUM" ||
      code === "MHL" ||
      code === "MNP" ||
      code === "PLW" ||
      code === "PRI" ||
      code === "USA" ||
      code === "VIR"
    ) {
      return match[1] + (match[1] && match[2] ? "-" + match[2] : "");
    } else if (code === "ARG") {
      return match[1] + (match[1] && match[2] ? match[2] : "") + (match[2] && match[3] ? match[3] : "");
    } else if (code === "CAN") {
      return (
        match[1] +
        (match[1] && match[2] ? match[2] : "") +
        (match[2] && match[3] ? match[3] : "") +
        (match[3] && match[4] ? " " + match[4] : "") +
        (match[4] && match[5] ? match[5] : "") +
        (match[5] && match[6] ? match[6] : "")
      );
    } else if (code === "CYM") {
      return match[1] + (match[1] && match[2] ? match[2] : "") + (match[2] && match[3] ? "-" + match[3] : "");
    } else if (
      code === "CZE" ||
      code === "GRC" ||
      code === "LBN" ||
      code === "MLT" ||
      code === "MSR" ||
      code === "SVK" ||
      code === "SOM" ||
      code === "SWE" ||
      code === "NLD"
    ) {
      return match[1] + (match[1] && match[2] ? " " + match[2] : "");
    } else if (code === "FLK" || code === "TCA") {
      return match[1] + (match[1] && match[2] ? " " + match[2] : "") + (match[2] && match[3] ? match[3] : "");
    } else if (code === "GIB") {
      return (
        match[1] +
        (match[1] && match[2] ? match[2] : "") +
        (match[2] && match[3] ? " " + match[3] : "") +
        (match[3] && match[4] ? match[4] : "")
      );
    } else {
      return match[1];
    }
  } else {
    return "";
  }
};

export const formatAccountNumber = (data, region) => {
  const bankToRegex = {
    itau: /^(\d{1,7})/,
    brou: /^(\d{1,14})/,
  };
  const countToRegex = {
    BOL: /^(\d{1,14})/,
    PRY: /^(\d{1,16})/,
    ECU: /^(\d{1,20})/,
    PER: /^(\d{1,20})/,
    ARG: /^(\d{1,22})/,
    CHL: /^(\d{1,12})/,
  };
  if (!countToRegex[region]) return data.replace(/[^\d]/g, "");
  const match = data?.match(countToRegex[region]);
  if (match) return match[1];
  else return "";
};

// neosurf voucher number
export const formatVoucherNumber = (data) => {
  let match = data?.replace(/[^a-zA-Z0-9]/g, "")?.match(/^([a-zA-Z0-9]{1,4})([a-zA-Z0-9]{0,3})([a-zA-Z0-9]{0,3})/);

  if (match) {
    return match[1] + (match[2] ? "-" + match[2] : "") + (match[3] ? "-" + match[3] : "");
  } else {
    return "";
  }
};

export const formatArray = (array, itemsPerCol) => {
  return array.reduce((result, item, index) => {
    const chunkIndex = Math.floor(index / itemsPerCol);

    if (!result[chunkIndex]) {
      result[chunkIndex] = []; // create a new row if it doesn't exist
    }

    result[chunkIndex].push(item);
    return result;
  }, []);
};
