import React, { useState, useEffect } from "react";
import "./Channel.scss";
import Selected from "../../../img/check.svg";
import { connect } from "react-redux";
import { setChannel, setPayChannel, setPaymentInfo, changeMethod } from "../../../redux/actions";
import uuid from "react-uuid";
import { useTranslation } from "react-i18next";
var classNames = require("classnames");

function Channel({ data = { data: [], key: "" }, loading = () => {}, subChannel, handleError, ...props }) {
  const { t, i18n } = useTranslation();
  const [moreState, setMoreState] = useState(false);
  const [borderStatus, setBorderStatus] = useState(false);
  useEffect(() => {
    data?.data?.forEach((item) => {
      !item.logo && setBorderStatus(true);
    });
  }, []);

  const handleFail = (err, notice) => {
    handleError(err, notice);
    props.setChannel("");
    props.setPayChannel("");
  };

  const setChannelData = () => {
    let channelList = props.channelList[props.method];

    if (subChannel) {
      channelList = props.channel?.sub_channels?.channels;
      props.setChannel({
        ...props.channel,
        sub_channels: {
          ...props.channel.sub_channels,
          selected: channelList?.find((item) => {
            return item?.key === data?.key;
          }),
        },
      });
    } else {
      const selectedOne = channelList?.find((item) => {
        return item?.key === data?.key;
      });
      if (selectedOne?.sub_channels?.channels) {
        props.setChannel({
          ...selectedOne,
          sub_channels: {
            ...selectedOne.sub_channels,
            selected: selectedOne.sub_channels.channels[0],
          },
        });
      } else {
        props.setChannel(selectedOne);
      }
    }
  };

  return (
    <div
      className={`channelContainer ${
        (subChannel ? props.channel.sub_channels.selected.key : props.channel?.key) === data?.key && "channel--border"
      }`}
      style={{
        border: borderStatus ? "solid 1px rgb(209, 209, 209)" : "solid 1px white",
      }}
      onClick={() => {
        if (props.method === "Wallet") {
          props.changeMethod(props.method, data?.data[0]?.channel, loading, handleFail, setChannelData);
        } else {
          setChannelData();
        }
        setMoreState((prev) => !prev);
      }}
      ga-data={data["ga-data"] || ""}
    >
      {!moreState && (
        <div>
          {data?.data?.map((item, index) => {
            if (index < 6) {
              return (
                <div
                  className="channel"
                  style={{ backgroundColor: !item?.logo ? "#f1f4f8" : "transparent" }}
                  ga-data={data["ga-data"] || ""}
                  key={index}
                >
                  {item?.logo && (
                    <img
                      className={classNames("channel__image", {
                        "channel--grayscale":
                          (subChannel ? props.channel.sub_channels.selected.key : props.channel?.key) !== data?.key,
                      })}
                      src={item?.logo}
                      alt=""
                      onLoad={() => {
                        setBorderStatus(true);
                      }}
                      ga-data={data["ga-data"] || ""}
                    />
                  )}
                  {item?.bank_name && (
                    <p className="channel__text" ga-data={data["ga-data"] || ""} key={index}>
                      {item?.bank_name}
                    </p>
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
          {data.name && (
            <p className="channel__text" ga-data={data["ga-data"] || ""}>
              {data?.name}
            </p>
          )}
        </div>
      )}
      {moreState && (
        <div>
          {data?.data?.map((item) => {
            return (
              <div
                className="channel"
                key={uuid()}
                style={{ backgroundColor: !item?.logo ? "#f1f4f8" : "transparent" }}
                ga-data={data["ga-data"] || ""}
              >
                {item?.logo ? (
                  <img
                    className={classNames("channel__image", {
                      "channel--grayscale":
                        (subChannel ? props.channel.sub_channels.selected.key : props.channel?.key) !== data?.key,
                    })}
                    src={item?.logo}
                    alt=""
                    ga-data={data["ga-data"] || ""}
                  />
                ) : (
                  <p className="channel__text" ga-data={data["ga-data"] || ""}>
                    {item?.bank_name}
                  </p>
                )}
              </div>
            );
          })}
          {data.name && (
            <p className="channel__text" ga-data={data["ga-data"] || ""}>
              {data?.name}
            </p>
          )}
        </div>
      )}
      {data?.data?.length > 5 && !moreState && (
        <div className="channel__more">
          <p>....</p>
        </div>
      )}
      {(subChannel ? props.channel.sub_channels.selected.key : props.channel?.key) === data?.key && (
        <img className="channel__selected" src={Selected} alt="" />
      )}
      {props.promotions?.[props.method]?.[data?.data[0]?.channel] && (
        <img
          className="channel__promotion"
          src={
            props.promotions?.[props.method]?.discountNotice[i18n.language] ||
            props.promotions?.[props.method]?.discountNotice.en
          }
          alt=""
        />
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    channel: state.channel,
    method: state.method,
    channelList: state.channelList,
    prepayId: state.prepayId,
    tradeInfo: state.tradeInfo,
    payChannel: state.payChannel,
    promotions: state.promotions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChannel: (item) => dispatch(setChannel(item)),
    setPayChannel: (item) => dispatch(setPayChannel(item)),
    setPaymentInfo: (item) => dispatch(setPaymentInfo(item)),
    changeMethod: (method, channel, setIsLoading, handleError, setChannelData) =>
      dispatch(changeMethod(method, channel, setIsLoading, handleError, setChannelData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Channel);
